import React from "react"
import Layout from "../components/layout"
import ContactForm from "../components/Forms/ContactForm"

const ContactUsPage = () => {
  return (
    <Layout>
      <ContactForm />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "40px",
        }}
      >
        {/* <h4>
        Mailing Address 
        </h4>
        <span>
        P.O. Box 431413,
        </span>
        <span>
        Minneapolis, MN, 55443,
        </span> */}

        <span>
          <h4>Phone</h4>
        </span>
        <span>(763)777-5699</span>
        <span>
          <h4>Email</h4>
        </span>

        <span>agsisto@yahoo.com</span>
      </div>

      {/* <h1>{data.allContentfulContactInformation.nodes.name}</h1>
            <h4>
              Location 
              </h4>
              <span>
              {data.allContentfulContactInformation.nodes.address}
              </span>
          <span>
        <h4>
          Phone
        </h4>
      </span>
        <span>{data.allContentfulContactInformation.nodes.phone}</span>
        <span>
      <h4>Email</h4>
      </span>
      
      <span>{data.allContentfulContactInformation.nodes.email}</span>
      */}
    </Layout>
  )
}

export default ContactUsPage
