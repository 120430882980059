import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useForm} from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.success.main,
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const {register, handleSubmit} = useForm()

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
         Get In Touch
        </Typography>
        <form className={classes.form} name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
          {/* noValidate onSubmit={handleSubmit((data) =>alert(JSON.stringify(data)))} */}
        <TextField
            margin="normal"
            inputRef={register}
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            type="text"
            autoComplete="name"
            autoFocus
          />
        <TextField
            margin="normal"
            inputRef={register}
            required
            fullWidth
            id="company"
            label="Company"
            name="company"
            type="text"
            autoComplete="company"
            autoFocus
          />
          <TextField 
          margin="normal"
          inputRef={register}
          multiline
          fullWidth
          name="phone"
          label="Phone"
          id="phone"
          autoFocus
        />
          <TextField
            margin="normal"
            inputRef={register}
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
          />
          <TextField 
          margin="normal"
          inputRef={register}
          multiline
          fullWidth
          autoFocus
          rowsMax={4}
          name="message"
          label="Your Message"
          type="textarea"
          id="message"
          />
           <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            Send
          </Button>
        </form>
      </div>
    </Container>
  );
}